import { navigate, useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Loading from 'src/components/Library/Loading/Loading'

const BaseLinkPage = () => {
  const { pathname } = useLocation()
  let location = ''
  let linkType = ''

  if (pathname.includes('/app/baselink/form')) {
    location = pathname.split('/app/baselink')[1]
    linkType = 'form'
    navigate(`/workflows-form?linkData=${location}`)
  } else if (pathname.includes('/app/baselink/public/grid')) {
    location = pathname.split('/app/baselink')[1]
    linkType = 'grid'
    navigate(`/workflows-grid?linkData=${location}`)
  } else if (pathname.includes('/app/baselink')) {
    location = pathname.split('/app/baselink')[1]
    linkType = 'baselink'
    navigate(`/app/bases?linkData=${location}&linkType=${linkType}`)
  } else if (pathname.includes('/notification')) {
    location = pathname.split('/notification')[1]
    linkType = 'notification'
    navigate(`/app/bases?linkData=${location}&linkType=${linkType}`)
  } else {
    navigate('/app/bases')
  }

  return (
    <>
      <Metadata title="Loading" description="Loading" />

      <Loading />
    </>
  )
}

export default BaseLinkPage
